import logo from './logo.svg';
import './App.css';

function App() {
  // Authenticate the user, and get permission to request payments from them:
  const scopes = ['payments'];

  // Read more about this callback in the SDK reference:
  function onIncompletePaymentFound(payment) { /* ... */ };

  var Pi = window.Pi
  Pi.authenticate(scopes, onIncompletePaymentFound).then(function (auth) {
    console.log(`Hi there! You're ready to make payments!`);
  }).catch(function (error) {
    console.error(error);
  });

  return (
    <div className="App">
      <p>Test Launch</p>       
    </div>
  );
}

export default App;
